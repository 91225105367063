import * as React from "react"

import { H1, H2, H3, Paragraph, Title } from "../components/Typography"
import { graphql, navigate } from "gatsby"

import AppLayout from "../components/AppLayout"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const Wrapper = styled.section`
  margin: 50px 0 100px 0;

  & ol {
    margin: 0 0 0 15px;
    padding: 0;
  }
`

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5vh;
`

const Homepage: React.FC = () => {
  const { i18n } = useTranslation()

  React.useEffect(() => {
    if (i18n.language !== "cs") {
      navigate("/privacy-policy")
    }
  }, [i18n.language])

  return (
    <AppLayout>
      <Sections>
        <Wrapper>
          <Paragraph>
            <Title>
              Informace o zpracov&aacute;n&iacute; osobn&iacute;ch &uacute;dajů
            </Title>
            <H3>Z&aacute;kladn&iacute; shrnut&iacute;</H3>
            <p>
              Společnost VAFO PRAHA, s.r.o. prov&aacute;d&iacute;
              zpracov&aacute;n&iacute; Va&scaron;ich osobn&iacute;ch
              &uacute;dajů, neboť je to nezbytn&eacute; pro naplněn&iacute;
              smluvn&iacute;ch vztahů ohledně Va&scaron;&iacute; &uacute;časti
              na věrnostn&iacute;m syst&eacute;mu. Pokud jste na
              věrnostn&iacute;m pouzdru vyznačil(a) souhlas se
              zpracov&aacute;n&iacute;m Va&scaron;ich osobn&iacute;ch
              &uacute;dajů pro &uacute;čely zas&iacute;l&aacute;n&iacute;
              obchodn&iacute;ch sdělen&iacute; (marketingov&yacute;ch
              nab&iacute;dek), budou Va&scaron;e osobn&iacute; &uacute;daje
              zpracov&aacute;v&aacute;ny tak&eacute; pro tento &uacute;čel.
              Tento souhlas můžete kdykoliv odvolat a obchodn&iacute;
              sdělen&iacute; V&aacute;m pak nebudou d&aacute;le
              zas&iacute;l&aacute;na. Shrom&aacute;žděn&iacute; Va&scaron;ich
              osobn&iacute;ch &uacute;dajů proběhne prostřednictv&iacute;m
              provozovatele maloobchodn&iacute; provozovny (zpracovatele
              osobn&iacute;ch &uacute;dajů), a to na z&aacute;kladě smlouvy o
              zpracov&aacute;n&iacute; osobn&iacute;ch &uacute;dajů
              uzavřen&eacute; mezi společnost&iacute; VAFO PRAHA, s.r.o. a
              takov&yacute;m zpracovatelem.
            </p>
            <H3>Totožnost a kontaktn&iacute; &uacute;daje spr&aacute;vce</H3>
            <ol>
              <li>
                <p>
                  Spr&aacute;vcem Va&scaron;ich osobn&iacute;ch &uacute;dajů je
                  obchodn&iacute; společnost VAFO PRAHA, s.r.o.,
                  se&nbsp;s&iacute;dlem K Brůdku 94, 252 19
                  Chr&aacute;&scaron;ťany, IČ: 614 99 587, zapsan&aacute;
                  v&nbsp;obchodn&iacute;m rejstř&iacute;ku veden&eacute;m
                  Městsk&yacute;m soudem v Praze odd&iacute;l C, vložka 30564
                  (d&aacute;le jen jako &bdquo;,
                  <strong>spr&aacute;vce</strong>&ldquo;).
                </p>
              </li>
              <li>
                <p>
                  Kontaktn&iacute; &uacute;daje spr&aacute;vce jsou
                  n&aacute;sleduj&iacute;c&iacute;: adresa pro
                  doručov&aacute;n&iacute; K&nbsp;Brůdku 94, 25219
                  Chr&aacute;&scaron;ťany, adresa elektronick&eacute;
                  po&scaron;ty osobniudaje@vafo.cz, telefon 257952141.
                </p>
              </li>
              <li>
                <p>
                  Spr&aacute;vce nejmenoval pověřence pro ochranu
                  osobn&iacute;ch &uacute;dajů.
                </p>
              </li>
            </ol>
            <H3>
              Pr&aacute;vn&iacute; z&aacute;klad zpracov&aacute;n&iacute;
              osobn&iacute;ch &uacute;dajů
            </H3>
            <ol>
              <li>
                <p>
                  Pr&aacute;vn&iacute;m z&aacute;kladem zpracov&aacute;n&iacute;
                  Va&scaron;ich osobn&iacute;ch &uacute;dajů je skutečnost, že
                  toto zpracov&aacute;n&iacute; je nezbytn&eacute; pro
                  splněn&iacute; smlouvy mezi V&aacute;mi a spr&aacute;vcem nebo
                  pro proveden&iacute; opatřen&iacute; spr&aacute;vcem před
                  uzavřen&iacute;m takov&eacute; smlouvy ve smyslu čl. 6 odst. 1
                  p&iacute;sm. b) Nař&iacute;zen&iacute; Evropsk&eacute;ho
                  parlamentu a Rady 2016/679 o ochraně fyzick&yacute;ch osob v
                  souvislosti se zpracov&aacute;n&iacute;m osobn&iacute;ch
                  &uacute;dajů a o voln&eacute;m pohybu těchto &uacute;dajů a o
                  zru&scaron;en&iacute; směrnice 95/46/ES (obecn&eacute;
                  nař&iacute;zen&iacute; o ochraně osobn&iacute;ch &uacute;dajů)
                  (d&aacute;le jen &bdquo;
                  <strong>nař&iacute;zen&iacute;</strong>&ldquo;).
                </p>
              </li>
              <li>
                <p>
                  Pokud jste na věrnostn&iacute;m pouzdru vyznačil(a) souhlas se
                  zpracov&aacute;n&iacute;m Va&scaron;ich osobn&iacute;ch
                  &uacute;dajů pro &uacute;čely zas&iacute;l&aacute;n&iacute;
                  obchodn&iacute;ch sdělen&iacute; (marketingov&yacute;ch
                  nab&iacute;dek), je pr&aacute;vn&iacute;m z&aacute;kladem
                  zpracov&aacute;n&iacute; Va&scaron;ich osobn&iacute;ch
                  &uacute;dajů tak&eacute; V&aacute;&scaron; souhlas
                  d&aacute;van&yacute; spr&aacute;vci ve smyslu čl. 6 odst. 1
                  p&iacute;sm. a) nař&iacute;zen&iacute;.
                </p>
              </li>
            </ol>
            <H3>
              &Uacute;čel zpracov&aacute;n&iacute; osobn&iacute;ch &uacute;dajů
            </H3>
            <ol>
              <li>
                <p>
                  &Uacute;čelem zpracov&aacute;n&iacute; Va&scaron;ich
                  osobn&iacute;ch &uacute;dajů je plněn&iacute; smlouvy mezi
                  V&aacute;mi a spr&aacute;vcem ohledně Va&scaron;&iacute;
                  &uacute;časti na věrnostn&iacute;m syst&eacute;mu.
                </p>
              </li>
              <li>
                <p>
                  Pokud jste na věrnostn&iacute;m pouzdru vyznačil(a) souhlas se
                  zpracov&aacute;n&iacute;m Va&scaron;ich osobn&iacute;ch
                  &uacute;dajů pro &uacute;čely zas&iacute;l&aacute;n&iacute;
                  obchodn&iacute;ch sdělen&iacute; (marketingov&yacute;ch
                  nab&iacute;dek), je &uacute;čelem zpracov&aacute;n&iacute;
                  Va&scaron;ich osobn&iacute;ch &uacute;dajů tak&eacute;
                  zas&iacute;l&aacute;n&iacute; obchodn&iacute;ch sdělen&iacute;
                  spr&aacute;vcem Va&scaron;&iacute; osobě.
                </p>
              </li>
              <li>
                <p>
                  Ze strany spr&aacute;vce nedoch&aacute;z&iacute; k
                  automatick&eacute;mu individu&aacute;ln&iacute;mu
                  rozhodov&aacute;n&iacute; ve smyslu čl. 22
                  nař&iacute;zen&iacute;.
                </p>
              </li>
            </ol>
            <H3>Doba uložen&iacute; osobn&iacute;ch &uacute;dajů</H3>
            <ol>
              <li>
                <p>
                  Va&scaron;e osobn&iacute; &uacute;daje budou
                  zpracov&aacute;v&aacute;ny po dobu trv&aacute;n&iacute;
                  &uacute;činků pr&aacute;v a povinnost&iacute; ze smlouvy, a
                  d&aacute;le po dobu nutnou pro &uacute;čely
                  archivov&aacute;n&iacute; podle
                  př&iacute;slu&scaron;n&yacute;ch obecně
                  z&aacute;vazn&yacute;ch pr&aacute;vn&iacute;ch předpisů,
                  nejd&eacute;le v&scaron;ak po dobu stanovenou obecně
                  z&aacute;vazn&yacute;mi pr&aacute;vn&iacute;mi předpisy.
                </p>
              </li>
              <li>
                <p>
                  Doba, po kterou budou uloženy Va&scaron;e osobn&iacute;
                  &uacute;daje zpracov&aacute;van&eacute; spr&aacute;vcem pro
                  &uacute;čely zas&iacute;l&aacute;n&iacute; obchodn&iacute;ch
                  sdělen&iacute; Va&scaron;&iacute; osobě (čl. 3.2), je čtyři
                  (4) roky od udělen&iacute; Va&scaron;eho souhlasu s
                  takov&yacute;m zpracov&aacute;n&iacute;m, nejd&eacute;le
                  v&scaron;ak do odvol&aacute;n&iacute; Va&scaron;eho souhlasu
                  se zpracov&aacute;n&iacute;m Va&scaron;ich osobn&iacute;ch
                  &uacute;dajů pro tento &uacute;čel zpracov&aacute;n&iacute;.
                </p>
              </li>
            </ol>
            <H3>
              Dal&scaron;&iacute; př&iacute;jemci osobn&iacute;ch &uacute;dajů
            </H3>
            <ol>
              <li>
                <p>
                  Dal&scaron;&iacute;mi př&iacute;jemci Va&scaron;ich
                  osobn&iacute;ch &uacute;dajů budou osoby
                  zaji&scaron;ťuj&iacute;c&iacute; pro spr&aacute;vce
                  technick&eacute; služby souvisej&iacute;c&iacute; s provozem
                  věrnostn&iacute;ho syst&eacute;mu, včetně provozu software a
                  ukl&aacute;d&aacute;n&iacute; dat, a d&aacute;le
                  provozovatel&eacute; prodejen, kteř&iacute; se jako partneři
                  &uacute;častn&iacute; věrnostn&iacute;ho syst&eacute;mu
                  (zpracovatel&eacute; osobn&iacute;ch &uacute;dajů).
                </p>
              </li>
              <li>
                <p>
                  Dal&scaron;&iacute;mi př&iacute;jemci Va&scaron;ich
                  osobn&iacute;ch &uacute;dajů zpracov&aacute;van&yacute;ch
                  spr&aacute;vcem pro &uacute;čely zas&iacute;l&aacute;n&iacute;
                  obchodn&iacute;ch sdělen&iacute; Va&scaron;&iacute; osobě (čl.
                  3.2) budou osoby tvoř&iacute;c&iacute; se spr&aacute;vcem
                  koncern a osoby zaji&scaron;ťuj&iacute;c&iacute; v souladu s
                  pokyny spr&aacute;vce marketingov&eacute; služby pro
                  spr&aacute;vce (zpracovatel&eacute; osobn&iacute;ch
                  &uacute;dajů).
                </p>
              </li>
              <li>
                <p>
                  Spr&aacute;vce nem&aacute; v &uacute;myslu předat Va&scaron;e
                  osobn&iacute; &uacute;daje do třet&iacute; země (do země mimo
                  EU) nebo mezin&aacute;rodn&iacute; organizaci, a to s
                  v&yacute;jimkou před&aacute;n&iacute; Va&scaron;ich
                  osobn&iacute;ch &uacute;dajů pro &uacute;čely
                  zas&iacute;l&aacute;n&iacute; obchodn&iacute;ch sdělen&iacute;
                  Va&scaron;&iacute; osobě (čl. 3.2) do Spojen&yacute;ch
                  st&aacute;tů americk&yacute;ch společnosti SendGrid, Inc., se
                  s&iacute;dlem 1801 California Street, Suite 500 Denver,
                  Colorado 80202. Společnost SendGrid, Inc. je certifikovanou
                  osobou v r&aacute;mci &scaron;t&iacute;tu EU&ndash;USA na
                  ochranu soukrom&iacute; ve smyslu prov&aacute;děc&iacute;ho
                  rozhodnut&iacute; Komise (EU) 2016/1250 ze dne 12. července
                  2016 vydan&eacute;ho podle směrnice Evropsk&eacute;ho
                  parlamentu a Rady 95/46/ES o odpov&iacute;daj&iacute;c&iacute;
                  &uacute;rovni ochrany poskytovan&eacute; &scaron;t&iacute;tem
                  EU&ndash;USA na ochranu soukrom&iacute;.
                </p>
              </li>
            </ol>
            <H3>
              Pr&aacute;va subjektu &uacute;dajů v př&iacute;padě
              zpracov&aacute;n&iacute; v souvislosti s &uacute;čast&iacute; na
              věrnostn&iacute;m syst&eacute;mu
            </H3>
            <ol>
              <li>
                <p>
                  Za podm&iacute;nek stanoven&yacute;ch v nař&iacute;zen&iacute;
                  m&aacute;te pr&aacute;vo požadovat od spr&aacute;vce
                  př&iacute;stup k Va&scaron;im osobn&iacute;m &uacute;dajům,
                  pr&aacute;vo na opravu nebo v&yacute;maz Va&scaron;ich
                  osobn&iacute;ch &uacute;dajů, popř&iacute;padě omezen&iacute;
                  jejich zpracov&aacute;n&iacute; a d&aacute;le pr&aacute;vo na
                  přenositelnost Va&scaron;ich osobn&iacute;ch &uacute;dajů.
                </p>
              </li>
              <li>
                <p>
                  Pokud byste se domn&iacute;val(a), že
                  zpracov&aacute;n&iacute;m Va&scaron;ich osobn&iacute;ch
                  &uacute;dajů bylo poru&scaron;eno či je
                  poru&scaron;ov&aacute;no nař&iacute;zen&iacute;, m&aacute;te
                  mimo jin&eacute; pr&aacute;vo podat st&iacute;žnost u
                  dozorov&eacute;ho &uacute;řadu.
                </p>
              </li>
              <li>
                <p>
                  Nem&aacute;te povinnost osobn&iacute; &uacute;daje poskytnout.
                  Poskytnut&iacute; Va&scaron;ich osobn&iacute;ch &uacute;dajů
                  je nutn&yacute;m požadavkem pro uzavřen&iacute; a
                  plněn&iacute; smlouvy (&uacute;časti věrnostn&iacute;ho
                  syst&eacute;mu) a bez poskytnut&iacute; Va&scaron;ich
                  osobn&iacute;ch &uacute;dajů nen&iacute; možn&eacute; smlouvu
                  uzavř&iacute;t či j&iacute; ze strany spr&aacute;vce splnit.
                </p>
              </li>
            </ol>
            <H3>
              Pr&aacute;va subjektu &uacute;dajů v př&iacute;padě
              zpracov&aacute;n&iacute; za &uacute;čelem
              zas&iacute;l&aacute;n&iacute; obchodn&iacute;ch sdělen&iacute;.
            </H3>
            <p>
              V př&iacute;padě osobn&iacute;ch &uacute;dajů
              zpracov&aacute;van&yacute;ch spr&aacute;vcem pro &uacute;čely
              zas&iacute;l&aacute;n&iacute; obchodn&iacute;ch sdělen&iacute;
              Va&scaron;&iacute; osobě (čl. 3.2):
            </p>
            <ol>
              <li>
                <p>
                  nem&aacute;te povinnost osobn&iacute; &uacute;daje poskytnout.
                  Poskytnut&iacute; Va&scaron;ich osobn&iacute;ch &uacute;dajů
                  nen&iacute; z&aacute;konn&yacute;m či smluvn&iacute;m
                  požadavkem a ani nen&iacute; požadavkem, kter&yacute; je
                  nutn&yacute; k uzavřen&iacute; smlouvy
                </p>
              </li>
              <li>
                <p>
                  m&aacute;te pr&aacute;vo kdykoli odvolat souhlas se
                  zpracov&aacute;n&iacute;m Va&scaron;ich osobn&iacute;ch
                  &uacute;dajů dan&yacute; spr&aacute;vci. T&iacute;mto
                  v&scaron;ak nen&iacute; dotčena z&aacute;konnost
                  zpracov&aacute;n&iacute; Va&scaron;ich osobn&iacute;ch
                  &uacute;dajů před takov&yacute;mto odvol&aacute;n&iacute;m
                  souhlasu. V&aacute;&scaron; souhlas se
                  zpracov&aacute;n&iacute;m Va&scaron;ich osobn&iacute;ch
                  &uacute;dajů můžete odvolat kliknut&iacute;m na
                  hypertextov&yacute; odkaz uveden&yacute; v každ&eacute;
                  zpr&aacute;vě obchodn&iacute;ho sdělen&iacute;
                  zas&iacute;lan&eacute; spr&aacute;vcem nebo
                  naps&aacute;n&iacute;m zpr&aacute;vy elektronickou
                  po&scaron;tou na adresu spr&aacute;vce: osobniudaje@vafo.cz.
                </p>
              </li>
              <li>
                <p>
                  m&aacute;te kdykoliv pr&aacute;vo vzn&eacute;st n&aacute;mitku
                  proti zpracov&aacute;n&iacute; Va&scaron;ich osobn&iacute;ch
                  &uacute;dajů pro &uacute;čely př&iacute;m&eacute;ho
                  marketingu, včetně profilov&aacute;n&iacute;, pokud se
                  profilov&aacute;n&iacute; t&yacute;k&aacute; tohoto
                  př&iacute;m&eacute;ho marketingu. Pokud vznesete
                  n&aacute;mitku proti zpracov&aacute;n&iacute; Va&scaron;ich
                  osobn&iacute;ch &uacute;dajů pro &uacute;čely
                  př&iacute;m&eacute;ho marketingu, nebudou již Va&scaron;e
                  osobn&iacute; &uacute;daje pro tyto &uacute;čely d&aacute;le
                  zpracov&aacute;v&aacute;ny.
                </p>
              </li>
            </ol>
          </Paragraph>
        </Wrapper>
      </Sections>
    </AppLayout>
  )
}

export default Homepage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
