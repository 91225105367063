import styled from "styled-components"

export const Title = styled.h1`
  width: 100%;
  font-size: 40px;
  margin-top: 4px;
  margin-bottom: min(20px, 2vh);

  ${props => props.theme.breakpoints.S} {
    text-align: center;
  }
`

export const Paragraph = styled.p`
  ${props => props.theme.breakpoints.S} {
    max-width: 450px;
    text-align: center;
  }

  & > p {
    margin: 5px 0;
  }
`

export const H1 = styled.h1``

export const H2 = styled.h2`
  font-size: 30px;
  margin: 15px 0;
`

export const H3 = styled.h3`
  font-size: 25px;
`
